import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/** @jsx createElement */
import { createDefaultChildrenComponent } from './DefaultChildren';
import { createDefaultFallbackComponent } from './DefaultFallback';
import { createDefaultHeaderComponent } from './DefaultHeader';
import { createListViewComponent } from './ListView';
export function createFrequentlyBoughtTogetherComponent(_ref) {
  var createElement = _ref.createElement,
      Fragment = _ref.Fragment;
  return function FrequentlyBoughtTogether(props) {
    var _props$classNames, _props$children, _props$fallbackCompon, _props$headerComponen, _props$view;

    var translations = _objectSpread({
      title: 'Frequently bought together',
      sliderLabel: 'Frequently bought together products'
    }, props.translations);

    var classNames = (_props$classNames = props.classNames) !== null && _props$classNames !== void 0 ? _props$classNames : {};
    var children = (_props$children = props.children) !== null && _props$children !== void 0 ? _props$children : createDefaultChildrenComponent({
      createElement: createElement,
      Fragment: Fragment
    });
    var FallbackComponent = (_props$fallbackCompon = props.fallbackComponent) !== null && _props$fallbackCompon !== void 0 ? _props$fallbackCompon : createDefaultFallbackComponent();

    var Fallback = function Fallback() {
      return createElement(FallbackComponent, {
        Fragment: Fragment,
        createElement: createElement
      });
    };

    var Header = (_props$headerComponen = props.headerComponent) !== null && _props$headerComponen !== void 0 ? _props$headerComponen : createDefaultHeaderComponent({
      createElement: createElement,
      Fragment: Fragment
    });
    var ViewComponent = (_props$view = props.view) !== null && _props$view !== void 0 ? _props$view : createListViewComponent({
      createElement: createElement,
      Fragment: Fragment
    });

    var View = function View(viewProps) {
      return createElement(ViewComponent, _extends({
        classNames: classNames,
        itemComponent: props.itemComponent,
        items: props.items,
        translations: translations,
        Fragment: Fragment,
        createElement: createElement
      }, viewProps));
    };

    return children({
      classNames: classNames,
      Fallback: Fallback,
      Header: Header,
      recommendations: props.items,
      status: props.status,
      translations: translations,
      View: View
    });
  };
}